<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/lessonschedule/camp' }">训练营列表</el-breadcrumb-item>
                <el-breadcrumb-item>训练营编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ good_uuid ? '编辑训练营' : '添加训练营' }}</h3>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <div>
                <div style="height: 20px;"></div>
                <!--详情-->
                <el-form class="time-arrange-form" v-loading="loading" size="medium" :label-width="this.env.label_width">
                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="选择门店">
                                <el-select v-model="store_uuid" filterable placeholder="请选择门店" style="width: 100%"
                                    :disabled=!this.Tool.is_empty(this.good_uuid) @change="selectStore">
                                    <el-option v-for="item in store_optionals" :key="item.store_uuid"
                                        :label="item.store_name" :value="item.store_uuid">
                                        <span style="float: left">{{ item.store_name }}
                                            <!--{{item.city}}{{item.county}}{{item.address}}-->
                                        </span>
                                        <span style="float: right; color: #8492a6; font-size: 13px">
                                            【{{ item.company_name }}】
                                        </span>
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="选择教室">
                                <el-select v-model="info.classroom_uuid" filterable placeholder="请选择教室" style="width: 100%"
                                    :disabled=!this.Tool.is_empty(this.good_uuid) @change="selectClassroom">
                                    <el-option v-for="item in classroom_optionals" :key="item.classroom_uuid"
                                        :label="item.classroom_name" :value="item.classroom_uuid">
                                        <span style="float: left">{{ item.classroom_name }}
                                        </span>
                                        <span style="float: right; color: #8492a6; font-size: 13px">
                                            【{{ item.area }}平/可容纳{{ item.capacity }}人】
                                        </span>
                                    </el-option>
                                </el-select>
                            </el-form-item>


                            <el-form-item label="选择教练">
                                <el-select v-model="info.coach_uuid" filterable @change="selectCoach(info.coach_uuid)"
                                    placeholder="请选择教练" :disabled=bookings style="width: 100%">
                                    <el-option v-for="itemcoach in coach_optionals" :key="itemcoach.coach_uuid"
                                        :label="`${itemcoach.user_nick}(${itemcoach.user_name})`"
                                        :value="itemcoach.coach_uuid">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="选择课程">
                                <el-select v-model="info.lesson_uuid" filterable @change="selectLesson(info.lesson_uuid)"
                                    placeholder="请选择课程" :disabled=bookings style="width: 100%">
                                    <el-option v-for="itemlesson in lesson_optionals" :key="itemlesson.lesson_uuid"
                                        :label="itemlesson.lesson_name" :value="itemlesson.lesson_uuid">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="课程标题">
                                <el-input v-model="info.title" :disabled=bookings maxlength="200"
                                    show-word-limit></el-input>
                            </el-form-item>
                            <el-form-item label="容纳人数">
                                <span>{{
                                    select_classroom_data.capacity
                                }}人/{{ select_classroom_data.area }}平</span>
                            </el-form-item>
                            <el-form-item label="课程人数">
                                <span>{{ info.lesson_quota }}人</span>
                            </el-form-item>
                            <el-form-item label="人数上限">
                                <el-input-number v-model="info.quota" :step="1" :min="bookings_sum" :max="9999"
                                    step-strictly></el-input-number>
                                <span v-if="bookings_sum">已预约:{{ bookings_sum }}人</span>
                            </el-form-item>
                            <el-form-item label="最少开课人数">
                                <el-input-number v-model="info.bookings_min" :step="1" :disabled=bookings :min="0"
                                    :max="info.quota" step-strictly></el-input-number>
                            </el-form-item>
                            <el-form-item label="提前N小时确定">
                                <el-input-number v-if="this.Tool.is_empty(good_uuid)" v-model="info.determine_time"
                                    :step="1" :min="0" :max="999" step-strictly></el-input-number>
                                <span>开课前{{ info.determine_time }}小时确定是否达到最小开课人数。未达到则自动取消。0不设置</span>
                            </el-form-item>
                            <el-form-item label="课程时长">
                                <span>{{ info.lesson_duration }}分钟</span>
                            </el-form-item>
                            <!--                            <el-form-item label="参考价格">-->
                            <!--                                <span>￥{{ info.price_indication }}</span>-->
                            <!--                            </el-form-item>-->
                            <!--                            <el-form-item label="门店价格">-->
                            <!--                                <span>￥{{ info.price_store }}</span>-->
                            <!--                            </el-form-item>-->
                            <el-form-item label="原价（0不设置）">
                                <el-input-number v-model="info.originally_price" :step="0.01" :min="0" :max="999999"
                                    step-strictly></el-input-number>
                            </el-form-item>
                            <el-form-item label="售价">
                                <el-input-number v-model="info.price" :step="0.01" :disabled=bookings :min="0" :max="999999"
                                    step-strictly></el-input-number>
                            </el-form-item>
                            <el-form-item label="课程数量">
                                <el-input-number v-model="info.lesson_sum" :step="1" :disabled=bookings :min="1" :max="100"
                                    @change="selectlesson_sum()" step-strictly></el-input-number>
                            </el-form-item>
                            <el-form-item label="开始时间">
                                <div class="lesson_time" v-for="(item_lesson_time, index) in lesson_time" :key="index">
                                    <span class="item_lesson_time">第{{ index + 1 }}节</span>
                                    <el-date-picker v-model="item_lesson_time.date_time" type="datetime"
                                        placeholder="选择开始时间" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
                                        @change="selectStartTime(item_lesson_time, info.lesson_duration)"
                                        :disabled="bookings || info.lesson_duration === undefined">
                                    </el-date-picker>
                                    —
                                    <span class="item_lesson_time">{{ item_lesson_time.end_time }}</span>
                                    <el-link type="primary" style="margin:0 10px 0 10px;" @click="plus(index)">插入
                                    </el-link>
                                    <el-link type="danger" @click="reduce(index)">删除</el-link>
                                </div>

                            </el-form-item>

                            <el-form-item label="排队等候">
                                <el-switch v-model="info.is_queue_up" :active-value="1" :inactive-value="0"></el-switch>
                            </el-form-item>
                            <el-form-item label="允许退款">
                                <el-switch v-model="info.is_refund" :active-value="1" :inactive-value="0"></el-switch>
                                <template v-if="info.is_refund === 1">
                                    <el-input-number v-model="info.refund_time" style="margin-left: 10px;" :step="1"
                                        :min="1" :max="999999" step-strictly @blur="checkRefundTime" />
                                    <span
                                        style="display: inline-block;background-color: #fff;width: 100px;border: none;">小时前可退</span>
                                    <span v-if="good_uuid"
                                        style="display: inline-block;background-color: #fff; color: #fc142f;width: 220px;border: none">已有订单的课程退款时间编辑无效</span>
                                    <div class="form-tip">1.开课前6小时外取消，全额退款</div>
                                    <div class="form-tip">2.开课前6小时内仅取消订单不退款</div>
                                </template>
                            </el-form-item>
                            <el-form-item label="上架时间(选填)">
                                <el-date-picker v-model="info.publish_time" type="datetime" placeholder="选择上架时间"
                                    format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
                                    :picker-options="publishTimePickerOptions"
                                    @change="selectPublishTime(info.publish_time)" :disabled=bookings style="width: 100%">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="提示(选填)">
                                <el-input v-model="info.tip" :rows="3" maxlength="200" placeholder="提示内容"
                                    show-word-limit></el-input>
                            </el-form-item>
                            <el-form-item label="适用范围">
                                <el-radio v-model="info.range" :label=1 :disabled="!this.Tool.is_empty(good_uuid)">
                                    全部用户
                                </el-radio>
                                <el-radio v-model="info.range" :label=2 :disabled="!this.Tool.is_empty(good_uuid)">
                                    新用户专享
                                </el-radio>
                            </el-form-item>
                            <el-form-item>
                                <el-button v-if="is_auth('lessonschedule.camp.issave')" @click="save()" size="medium"
                                    type="primary">保存
                                </el-button>
                                <el-button
                                    v-if="is_auth('lessonschedule.camp.issave') && this.Tool.is_empty(this.good_uuid)"
                                    @click="createdinfo()" size="medium">清空
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            loading: false,                 // 加载状态

            store_optionals: [],            // 可选门店信息
            classroom_optionals: [],        // 可选教室信息
            coach_optionals: [],            // 可选教练信息
            lesson_optionals: [],           // 可选课程信息
            classroom_occupy: [],            // 教室占用数据


            select_store_data: null,        // 选中的门店数据
            // 选中的教室数据
            select_classroom_data: {
                capacity: '',
                area: '',
            },

            bookings: false,               // 是否有预约
            bookings_sum: 0,               // 预约人数
            good_uuid: '',                  // 课表uuid
            store_uuid: '',                 // 选择的门店id
            info: {},
            lesson_time: [],                 // 课程时间


            // 上架时间选择 配置
            publishTimePickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
        this.selectlesson_sum()
    },
    methods: {
        // 获取排课信息
        getInfo(good_uuid = '') {
            let postdata = {
                api_name: "lessonschedule.camp.getinfo",
                token: this.Tool.get_l_cache('token'),
                good_uuid,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                // this.loading = false
                if (json.code === 0) {
                    this.bookings_sum = json.data.bookings_sum
                    this.bookings = json.data.bookings_sum > 0 ? true : false
                    this.store_uuid = json.data.store_uuid
                    this.selectStore(this.store_uuid)   // 选择门店
                    this.info = json.data
                    // 处理课程时间
                    this.init_lesson_time(json.data.lesson_time)

                } else {
                    this.store_optionals = []
                    this.Tool.errormes(json)
                }

            })
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.createdinfo()      // 创建info
            this.getStore()         // 获取可选门店
            let good_uuid = this.$route.query.good_uuid
            // console.log('good_uuid', good_uuid)
            if (!this.Tool.is_empty(good_uuid)) {
                this.good_uuid = good_uuid
                this.getInfo(good_uuid)
            } else {
                this.loading = false
            }
        },
        // 创建info，清空info
        createdinfo() {
            this.info = {
                classroom_uuid: '',             // 选择的教室id
                title: '',
                quota: 0,
                lesson_sum: 0,
                bookings_min: 0,
                price: 0,
                originally_price: 0,
                determine_time: 2,
                publish_time: '',
                is_queue_up: 1,
                is_refund: 0,
                tip: '',
                range: 1,
                refund_time: "6"
            }
        },
        // 保存
        save() {
            let arrange_data = []
            let is_save = true

            /**验证必填数据**/
            // 判断是否有时间
            if (this.Tool.is_empty(this.store_uuid)) {
                is_save = false
                let message = `请选择门店`
                this.$message({ message, type: 'warning', duration: this.env.message_error, });
                return
            }
            // 判断是否有时间
            if (this.Tool.is_empty(this.info.classroom_uuid)) {
                is_save = false
                let message = `请选择教室`
                this.$message({ message, type: 'warning', duration: this.env.message_error, });
                return
            }
            // 判断是否有时间
            // if (this.Tool.is_empty(this.info.date)) {
            //     is_save = false
            //     let message = `请选择时间`
            //     this.$message({message, type: 'warning', duration: this.env.message_error,});
            //     return
            // }
            // 判断是否排课
            if (this.Tool.is_empty(this.info.lesson_uuid)) {
                is_save = false
                // let message = `周${this.info.week}（${this.info.date}） 未排课`
                let message = `未排课`
                this.$message({ message, type: 'warning', duration: this.env.message_error, });
                return
            }
            // 判断是否有教练
            // if (this.Tool.is_empty(this.info.coach_uuid)) {
            //     is_save = false
            //     // let message = `周${this.info.week}（${this.info.date}） 未排课`
            //     let message = `无教练信息`
            //     this.$message({message, type: 'warning', duration: this.env.message_error,});
            //     return
            // }
            // 判断是否有标题
            if (this.Tool.is_empty(this.info.title)) {
                is_save = false
                // let message = `周${this.info.week}（${this.info.date}） 未输入名称`
                let message = `未输入课程标题`
                this.$message({ message, type: 'warning', duration: this.env.message_error, });
                return
            }
            // 判断是否人数
            if (this.Tool.is_empty(this.info.quota) || this.info.quota === 0) {
                is_save = false
                // let message = `周${this.info.week}（${this.info.date}） 未输入人数上限`
                let message = `未输入人数上限`
                this.$message({ message, type: 'warning', duration: this.env.message_error, });
                return
            }
            // 判断是否有价格
            if (this.Tool.is_empty(this.info.price) || this.info.price === 0) {
                is_save = false
                // let message = `周${this.info.week}（${this.info.date}） 未输入预约价格`
                let message = `未输入预约价格`
                this.$message({ message, type: 'warning', duration: this.env.message_error, });
                return
            }
            // 判断是否选择开始时间
            // if (this.Tool.is_empty(this.info.start_time)) {
            //     is_save = false
            //     // let message = `周${this.info.week}（${this.info.date}） 未选择开始时间`
            //     let message = `未选择开始时间`
            //     this.$message({message, type: 'warning', duration: this.env.message_error,});
            //     return
            // }
            // 判断时间是否冲突


            // 生成提交数据
            this.info.store_uuid = this.store_uuid      // 门店id
            // this.info.date_ymd = this.info.date         // 时间
            arrange_data.push(this.info)

            if (!is_save) {
                return false
            }
            // console.log('arrange_data', arrange_data)
            // console.log('lesson_time', this.lesson_time)
            this.is_save(arrange_data, this.lesson_time)
        },
        // 提交保存
        is_save(arrange_data = [], lesson_time = []) {
            // 无排课信息
            if (this.Tool.is_empty(arrange_data) || this.Tool.is_empty(lesson_time)) {
                this.Tool.errormes({
                    "code": 10001,
                    "message": "未排课",
                })
                return false
            }

            // console.log('arrange_data', arrange_data)

            let postdata = {
                api_name: "lessonschedule.camp.issave",
                token: this.Tool.get_l_cache('token'),
                arrange_data: arrange_data[0],          // 课程信息
                lesson_time,                            // 时间信息
            }
            let reg = RegExp(
                /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
            );
            if (postdata.arrange_data["title"].match(reg)) {
                this.$message.error('课程名称不可包含特殊字符');
            } else {
                this.loading = true
                this.Tool.post_data('merchant', postdata, (json) => {
                    this.loading = false
                    if (json.code === 0) {
                        // 编辑模式下
                        if (!this.Tool.is_empty(this.good_uuid)) {
                            this.getInfo(this.good_uuid)        // 刷新数据
                        }
                        this.$message({
                            message: '操作成功', type: 'success',
                            duration: this.env.message_duration,
                            onClose: () => {
                                this.isreturn()    // 选中课程信息

                            }
                        });
                    } else {
                        this.Tool.errormes(json)
                    }
                })
            }
            // console.log('postdata', postdata)
        },
        // 返回
        isreturn() {
            // this.$router.push({path: '/lessonschedule/camp'})
            this.$router.go(-1)
        },
        // 获取可选门店
        getStore() {
            let postdata = {
                api_name: "lessonschedule.lessonarrange.getstore",
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.store_optionals = json.data.list
                } else {
                    this.store_optionals = []
                    this.Tool.errormes(json)
                }

            })
        },
        // 获取可选教室
        getClassroom(store_uuid) {
            let postdata = {
                api_name: "lessonschedule.lessonarrange.getclassroom",
                token: this.Tool.get_l_cache('token'),
                store_uuid
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false

                // 添加模式下初始化信息
                if (this.Tool.is_empty(this.good_uuid)) {
                    this.createdinfo()
                }

                if (json.code === 0) {
                    this.classroom_optionals = json.data.list
                    // 编辑模式下
                    if (!this.Tool.is_empty(this.good_uuid)) {
                        this.selectClassroom(this.info.classroom_uuid, 0)    // 选中教室
                    }
                } else {
                    this.classroom_optionals = []
                    if (json.code !== 10002) {
                        this.Tool.errormes(json)
                    }
                }
            })
        },
        // 获取可选教练
        getcoach(store_uuid) {
            let postdata = {
                api_name: "lessonschedule.lessonarrange.getcoach",
                token: this.Tool.get_l_cache('token'),
                store_uuid
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                this.info.select_coach = ''
                if (json.code === 0) {
                    this.coach_optionals = json.data.list
                } else {
                    this.coach_optionals = []
                    if (json.code !== 10002) {
                        this.Tool.errormes(json)
                    }
                }
            })
        },
        // 获取可选课程
        getlesson(store_uuid) {
            let postdata = {
                api_name: "lessonschedule.lessonarrange.getlesson",
                token: this.Tool.get_l_cache('token'),
                lesson_type: 2,
                store_uuid
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.lesson_optionals = json.data.list
                    // 编辑模式下
                    if (!this.Tool.is_empty(this.good_uuid)) {
                        this.selectLesson(this.info.lesson_uuid)    // 选中课程信息
                    }
                } else {
                    this.lesson_optionals = []
                    if (json.code !== 10002) {
                        this.Tool.errormes(json)
                    }
                }
            })
        },
        // 获取教室使用数据
        getClassroomOccupyTimeData() {
            console.log('获取教室占用数据')
            return false;
            // let postdata = {
            //     api_name: "lessonschedule.lessonarrange.getclassroomoccupy",
            //     token: this.Tool.get_l_cache('token'),
            //     classroom_uuid: this.classroom_uuid,
            //     datearea: this.getDateArea(this.selectWeek)
            // }
            // this.loading = true
            // this.Tool.post_data('merchant', postdata, (json) => {
            //     this.loading = false
            //     if (json.code === 0) {
            //         console.log('json.data', json.data)
            //         this.classroom_occupy = json.data
            //     } else {
            //         if (json.code !== 10002) {
            //             this.Tool.errormes(json)
            //         }
            //     }
            // })
        },
        // 获取教练时间占用数据
        getCoachOccupyTimeData() {

        },
        // 选择门店
        selectStore(store_uuid) {
            this.getClassroom(store_uuid)   // 获取教室数据
            this.getcoach(store_uuid)       // 获取教练数据
            this.getlesson(store_uuid)      // 获取课程数据

            for (let item of this.store_optionals) {
                if (item.store_uuid === store_uuid) {
                    this.select_store_data = item
                    break
                }
            }
        },
        // 选择教室
        selectClassroom(classroom_uuid, clearance_time = 1) {
            for (let item of this.classroom_optionals) {
                if (item.classroom_uuid === classroom_uuid) {
                    this.select_classroom_data = item
                    break
                }
            }
            if (clearance_time) {
                // this.info.date = ''
                // this.info.week = ''
            }
        },
        // 选择教练
        selectCoach(coach_uuid) {
            console.log('coach_uuid', coach_uuid)
        },
        // 根据日期转换成周几
        getDateW(date) {
            let i = new Date(date).getDay()
            let week = '';
            if (i === 1) week = '一'
            if (i === 2) week = '二'
            if (i === 3) week = '三'
            if (i === 4) week = '四'
            if (i === 5) week = '五'
            if (i === 6) week = '六'
            if (i === 0) week = '日'

            return week
        },
        // 选择课程
        selectLesson(lesson_uuid = 0) {
            // console.log('lesson_uuid', lesson_uuid)
            let lesson_data;
            for (let item of this.lesson_optionals) {
                if (item.lesson_uuid === lesson_uuid) {
                    lesson_data = item
                    break
                }
            }

            this.info.store_lesson_uuid = lesson_data.store_lesson_uuid
            this.info.lesson_name = lesson_data.lesson_name
            this.info.lesson_duration = lesson_data.lesson_duration
            this.info.price_indication = lesson_data.price_indication
            this.info.price_store = lesson_data.price_store
            this.info.lesson_quota = lesson_data.quota
            // 添加模式下
            if (this.Tool.is_empty(this.good_uuid)) {
                this.info.title = lesson_data.lesson_name
                this.info.price = lesson_data.price_store
                this.info.quota = lesson_data.quota
            }

            // this.info.end_time = this.computeEndTime(this.info.start_time, lesson_data.lesson_duration)
        },
        // 初始化课程时间
        init_lesson_time(lesson_time) {
            // console.log('lesson_time', lesson_time)
            this.lesson_time = []
            let i = 0
            for (let val of lesson_time) {


                let date = val.split(" ")[0]
                let time_data = (val.split(" ")[1]).split("-")
                console.log('val', val)
                console.log('i', i)
                console.log('date', date)
                console.log('time_data', time_data)

                this.lesson_time.push({
                    'date_time': date + ' ' + time_data[0],
                    'start_date': date,
                    'start_time': time_data[0],
                    'end_time': time_data[1],
                })
                i++
            }

        },
        // 输入课程数量
        selectlesson_sum() {
            this.lesson_time = []
            let lesson_sum = this.info.lesson_sum
            for (let i = 0; i < lesson_sum; i++) {

                this.lesson_time.push({
                    'date_time': '',
                    'start_date': '',
                    'start_time': '',
                    'end_time': '',
                })

            }

        },
        plus(index) {
            this.info.lesson_sum = this.lesson_time.length + 1
            this.lesson_time.splice(index, 0, {
                'date_time': '',
                'start_date': '',
                'start_time': '',
                'end_time': '',
            })
        },
        reduce(index) {
            this.info.lesson_sum = this.lesson_time.length - 1
            this.lesson_time.splice(index, 1)
        },
        // 选择上架时间
        selectPublishTime(publish_time) {
            console.log('publish_time', publish_time)
        },
        // 选择开始时间
        selectStartTime(item_lesson_time, lesson_duration) {
            let date_data = item_lesson_time.date_time.split(" ")
            item_lesson_time.start_date = date_data[0]
            item_lesson_time.start_time = date_data[1]
            item_lesson_time.end_time = this.computeEndTime(date_data[1], lesson_duration)
        },
        // 计算结束时间
        computeEndTime(start_time, lesson_duration) {
            let end_time = '暂无'
            // 判断是否选择了 开始时间和门店课程，只要有一个没选择 则不计算结束时间
            if (this.Tool.is_empty(start_time) || this.Tool.is_empty(lesson_duration)) {
                return end_time
            }
            // 开始时间转换成秒+课程时长
            let second = 0      // 秒数
            let start_time_data = start_time.split(":")     // 时间数据
            second = parseInt(start_time_data[0]) * 60 + parseInt(start_time_data[1]) + lesson_duration

            // 计算结束时间hh:mm
            let end_time_h = parseInt(second / 60)
            let end_time_m = parseInt(second % 60)
            // 转成字符串
            end_time_h = end_time_h < 10 ? '0' + end_time_h : end_time_h + ''
            end_time_m = end_time_m < 10 ? '0' + end_time_m : end_time_m + ''
            end_time = end_time_h + ':' + end_time_m

            return end_time
        },

        // 检测退款时间
        checkRefundTime() {
            const isFloat = this.Tool.isFloatString(this.info.refund_time);
            if (isFloat) {
                this.$message.warning("退款时间不能为小数");
                this.info.refund_time = parseInt(this.info.refund_time);
                return false;
            }
            if (this.info.refund_time < 1) {
                this.$message.warning("退款时间不能小于1小时");
                this.info.refund_time = 6;
                return false;
            }
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*时间表*/
.time-arrange {
    /*border: #3a8ee6 1px solid;*/
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 1px 1px 5px #d9d9d9;
    border-radius: 5px;
}

.font-size-mini {
    font-size: 12px;
}

/*课程表天*/
.timetable .time-arrange-column {
    width: 100% !important;
    position: relative !important;
}

.timetable .time-tip {
    width: 100%;
    position: absolute;
}

.timetable .time-h {
    height: 60px;
}

.timetable .time-h-border {
    border-top: #EBEEF5 1px solid;
}

.timetable .time-arrange-column-store-classroom {
    width: calc(100% - 45px);
    margin-left: 40px;
    position: absolute;
}


.time-arrange-list {
    /*margin-right: 20px;*/
    /*border: 1px solid #888888;*/
    /*border-radius: 5px;*/
    /*box-shadow: 1px 1px 5px #cccccc;*/
    padding: 0 5px;
}

/*小号字体*/
.text-se {
    font-size: 12px;
}

.time-arrange-column {
    display: flex;
    /*min-height: 960px;*/
    height: 1440px;
    width: 30px;
    border: 1px solid #EBEEF5;
    margin-right: 5px;
    font-size: 12px;
    /*margin-left: 5px;*/
}

.time-arrange-column-type {
    position: relative;
    width: 50%;
}

/*门店时间占用*/
.time-arrange-column-store-classroom-card {
    border-radius: 5px;
    width: 100%;
    background: #ffa940;
    /*position: relative;*/
    position: absolute;
    /*溢出滚动条*/
    overflow-x: auto;
    /*溢出隐藏*/
    /*overflow: hidden;*/
}

/*隐藏滚动条*/
.time-arrange-column-store-classroom-card::-webkit-scrollbar {
    width: 0 !important
}

/*教练时间占用*/

.time-arrange-form span {
    display: block;
    width: 100%;
    background-color: #f5f7fa;
    border: 1px solid #e4e7ed;
    height: 36px;
    border-radius: 4px;
    font-size: 12px;
    padding: 0 15px;
    box-sizing: border-box;
    /*text-align: center;*/
}

.lesson_time {
    display: flex;
    margin-bottom: 10px;
}

.item_lesson_time {
    width: 80px !important;
}
</style>
